import { Helmet } from 'react-helmet'

function App() {
  return (
    <>
      <Helmet>
        <title>
          Модний одяг та взуття | Сайт тимчасово не доступний | Modnafamilie
        </title>
      </Helmet>
      <div className='bg'></div>
    </>
  )
}

export default App
